/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function equalHeight(container)
{
    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = [],
        $el,
        topPosition = 0;

    jQuery(container).each(function()
    {
        $el = jQuery(this);
        jQuery($el).height('auto');
        topPosition = $el.position().top;
        var currentDiv = 0;

        if (currentRowStart !== topPosition)
        {
            for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++)
            {
                rowDivs[currentDiv].height(currentTallest);
            }

            rowDivs.length = 0; // empty the array
            currentRowStart = topPosition;
            currentTallest = $el.height();
            rowDivs.push($el);
        }

        else
        {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        }

        for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++)
        {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
}


(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var SoftwareDesign = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                //Smooth Scrolling functions
                //jQuery to collapse the navbar on scroll
                window.onscroll = function(){
                    if (document.body.scrollTop > 50) {
                        jQuery(".navbar-fixed-top").addClass("top-nav-collapse");
                    } else {
                        jQuery(".navbar-fixed-top").removeClass("top-nav-collapse");
                    }
                };

                //jQuery for page scrolling feature - requires jQuery Easing plugin
                jQuery(function() {
                    jQuery('a.page-scroll').bind('click', function(event) {
                        var jQueryanchor = jQuery(this);
                        jQuery('html, body').stop().animate({
                            scrollTop: jQuery(jQueryanchor.attr('href')).offset().top
                        }, 1500, 'easeInOutExpo');
                        event.preventDefault();
                    });
                });

                //Custom checkbox
                $('input[type="checkbox"]').each(function() {
                    if($(this).parent().hasClass("custom-checkbox") === false) {
                        $(this).wrap('<span class="custom-checkbox"></span>');
                    }
                });

                var checkBox = $('.custom-checkbox input[type="checkbox"]');
                if (checkBox.length) {
                    checkBox.each(function () {
                        if ($(this).is(':checked')) {
                            $(this).parent().addClass("selected");
                        }
                    });
                    $(checkBox).click(function () {
                        $(this).parent().toggleClass("selected");
                    });
                }

                //Custom radio
                $('input[type="radio"]').each(function() {
                    if($(this).parent().hasClass("custom-radio-btn") === false) {
                        $(this).wrap('<span class="custom-radio-btn"></span>');
                    }
                });

                var radio = $('.custom-radio-btn input[type="radio"]');
                $(radio).each(function () {
                    if ($(this).is(':checked')) {
                        $(this).parent().addClass("selected");
                    }
                });

                $(radio).click(function () {
                    var groupName = $(this).attr('name');
                    $('.custom-radio-btn input[type="radio"][name="' + groupName + '"]').each(function(){
                        $(this).parent().removeClass("selected");
                        $(this).parent().prop("checked", false);
                    });
                    $(this).parent().addClass("selected");
                    $(this).parent().prop("checked", true);
                });
                //
                jQuery(window).load(function () {
                    setTimeout(function () {
                        jQuery(window).trigger('resize').trigger('scroll');
                    }, 500);
                });
                // Custom Select
                $(".custom-select").select2({
                    minimumResultsForSearch: 9999999,
                    width: '100%',
                    dropdownAutoWidth : true
                });

                function initFocusEvents(selector) {
                    $(document).on('focus', selector, function()
                    {
                        var $this = $(this);
                        if ($this.next('.label-rest').length !== 0) {
                            $(this).next('.label-rest').removeClass('label-rest').addClass('label-hover');
                        } else {
                            $(this).parent().siblings('.label-rest').removeClass('label-rest').addClass('label-hover');
                        }
                    });

                    $(document).on('focusout', selector, function()
                    {
                        var $this = $(this);
                        var $labels = $this.next('.label-hover');

                        if ($labels.length === 0) {
                            $labels = $this.parent().siblings('.label-hover');
                        }

                        if (!$this.val()) {
                            $labels.removeClass('label-hover').addClass('label-rest');
                        }
                    });

                    var hideShowLabel = function()
                    {
                        if ($(this).val().length > 0)
                        {
                            $(this).next('label').css('visibility', 'hidden');
                        }

                        else
                        {
                            $(this).next('label').css('visibility', 'visible');
                        }
                    };

                    $(document).on('keyup', selector, function(e)
                    {
                        hideShowLabel.call(this, e);
                    });
                }

                initFocusEvents('.style-input');

                /*$(window).load(function()
                {
                    equalHeight('.row-equal-watch .col-equal');
                });*/

                equalHeight('.row-equal-watch .col-equal');

                $(window).resize(function()
                {
                    equalHeight('.row-equal-watch .col-equal');
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'page_template_default': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = SoftwareDesign;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// Global Utils & Functions
function findBootstrapEnvironment()
{
    var envs = ['xs', 'sm', 'md', 'lg'];

    var $el = jQuery('<div>');
    $el.appendTo(jQuery('body'));

    for (var i = envs.length - 1; i >= 0; i--) {
        var env = envs[i];

        $el.addClass('hidden-'+env);
        if ($el.is(':hidden')) {
            $el.remove();
            return env;
        }
    }
}

function callAjax(action, call, variables, method)
{
    if (typeof(variables) === "undefined")
    {
        variables = {};
    }

    if (typeof(method) === "undefined")
    {
        method = "POST";
    }

    var form_data = variables;

    form_data.action = action;

    jQuery.ajax(
    {
        type: method,
        url: ajaxurl,
        data: form_data,
        success: function(data)
        {
            call(data);
        },
        error: function(MLHttpRequest, textStatus, errorThrown)
        {

        }
    });
}

var ajaxLoading = false;

function ajaxLoader(div)
{
    if (ajaxLoading)
    {
        jQuery("#ajax-spinner").remove();

        ajaxLoading = false;

        return;
    }

    ajaxLoading = true;

    if (typeof(div) === "undefined")
    {
        div = "body";
    }

    jQuery(div).before('<div id="ajax-spinner"></div>');
}

//scrolling animations

window.sr = ScrollReveal();
sr.reveal('.component-two .content', { duration: 1000, delay:500 });
sr.reveal('.component-six .left .item-group ', {origin: 'left', distance:"300px", duration: 1000, delay:500 },200);
sr.reveal('.component-five .left ', {origin: 'left', distance:"300px", duration: 1000, delay:500 });
sr.reveal('.component-five .right', {origin: 'left', distance:"300px", duration: 1000, delay:1500 });
sr.reveal('.component-six .right .item-group', {origin: 'left', distance:"300px", duration: 1000, delay:1500 },200);
sr.reveal('.component-eight .person-holder', {distance:"10px", duration: 1000, delay:500 }, 200);
sr.reveal('.component-ten li', {origin: 'left', distance:"300px", duration: 1000, delay:500 }, 200);
sr.reveal('.component-eleven .component-block', {duration: 1000, delay:500 }, 200);
sr.reveal('.component-twelve .cp12-number', {origin: 'bottom', distance:"100px", duration: 1000, delay:500 }, 300);
sr.reveal('.component-thirteen i', { duration: 1000, delay:500 });
sr.reveal('.component-fourteen a', {origin: 'right', distance:"300px", duration: 1000, delay:500 });
sr.reveal('.component-fifteen .number-block, .component-fifteen .header-text, .component-fifteen .text-area', {origin: 'bottom', distance:"80px", duration: 1000, delay:500 }, 200);
sr.reveal('.component-sixteen .s-title', { origin: 'left', distance:"40px", duration: 1000 });
sr.reveal('.component-seventeen .filters', {origin: 'bottom', distance:"0px", duration: 1000, delay:500 });
sr.reveal('.component-eighteen h2', {origin: 'top', distance:"100px", duration: 1000, delay:500 });
sr.reveal('.component-nineteen .location-holder', {duration: 1000, delay:500 }, 200);
sr.reveal('.component-twenty-one .text-area, .component-twenty-three .button-holder', {distance:"10px", duration: 1500, delay:500 }, 200);
sr.reveal('.component-twenty-three .text-area, .component-twenty-three .item-holder', {distance:"10px", duration: 1000, delay:500 }, 200);
sr.reveal('.mailchimp-container', {origin: 'bottom', distance:"100px", duration: 1000, delay:500 });
sr.reveal('.component-wysiwyg li', {origin: 'left', distance:"300px", duration: 1000, delay:500}, 200);

//end scrolling animations

/**
 * Dialog Class
 * -------------
 *
 * Examples:
 * - First example:
 *    <a id='btn' href='#'>Open Dialog</a>
 *    <div id='my-modal' class='modal'>
 *      <div class='modal-content'>
 *        <div class='modal-header'>
 *          <span class='close'>X</span>
 *          <h3 class='title'>Title of The Modal</h3>
 *        </div>
 *        <div class='modal-body'>
 *          <p>My Content</p>
 *        </div>
 *      </div>
 *    </div>
 *
 *    <script>
 *      var myDialog = $.extend({}, Dialog);
 *      myDialog.elem('#my-modal');
 *      $('#btn').on('click', function(e){
 *        e.preventDefault();
 *        myDialog.open();
 *      });
 *    </script>
 *
 *
 * - Second example:
 *    <script>
 *      var myDialog = $.extend({}, Dialog);
 *      myDialog.data = {title: 'Title of Modal', content: 'My Message'}
 *      $('#btn').on('click', function(e){
 *        e.preventDefault();
 *        myDialog.open();
 *      });
 *    </script>
 */

var Dialog = {

    'data': {title:'', content: ''},

    'destroyDialogWhenClose': false,

    '$elem': undefined,

    'isSetEvents':  false,

    'getTemplate': function(){
        var that = this;
        var tpl  = '<div class="modal">';
        tpl += '<div class="modal-content">';
        tpl += '<span class="close">x</span>';
        tpl += '<h3 class="title">' + that.data.title + '</h3>';
        tpl += '<p>' + that.data.content + '</p>';
        tpl += '</div>';
        tpl += '</div>';

        return tpl;
    },

    'elem': function(elem){
        var that = this;
        that.$elem = jQuery(elem);
    },

    'open': function(){
        var that = this;
        var $body = jQuery('body');

        if(that.$elem === undefined) {
            that.$elem = jQuery(that.getTemplate());
        }


        if(that.isSetEvents === false){
            that.setEvents();
            that.isSetEvents = true;
        }

        if(that.destroyDialogWhenClose === true){
            $body.prepend(that.$elem);
        }

        $body.addClass('modal-is-open');

        that.show();
    },

    'close': function(){
        var that = this;
        if(that.destroyDialogWhenClose === true){
            that.$elem.remove();
        }else{
            that.hide();
        }
        jQuery('body').removeClass('modal-is-open');
    },

    'show': function(){
        var that = this;
        that.$elem.fadeIn(580);
    },

    'hide': function(){
        var that = this;
        that.$elem.fadeOut(240);
    },

    'setEvents': function(){
        var that = this;
        jQuery('.modal, .modal .background-blur').click(function(event){
            if (event.target !== this){
                return;
            }
            that.close();
        });

        jQuery('.modal .modal-content .close').click(function(){
            that.close();
        });

        jQuery(document).keyup(function(e) {
            if (e.keyCode === 27) {
                that.close();
            }
        });
    }
};
Number.prototype.formatMoney = function(c, d, t){
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};


